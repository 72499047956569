$(document).ready(function() {
    var consentIsSet = "unknown";
    var cookieBanner = "#alert-cookies";
    var consentString = "cookies_granted=";

    // Sets a cookie granting/denying consent, and displays some text on console/banner
    function setCookie(console_log, banner_text, consent) {
        //console.log(console_log);
        //$(cookieBanner).text(banner_text);
        //$(cookieBanner).fadeOut(1000);
        $(cookieBanner).hide();
        var d = new Date();
        var exdays = 30*12; //  1 year
        d.setTime(d.getTime()+(exdays*24*60*60*1000));
        var expires = "expires="+d.toGMTString();
        document.cookie = consentString + consent + "; " + expires + ";path=/";
        consentIsSet = consent;
    }

    function grantConsent() {
        if (consentIsSet == "true") return; // Don't grant twice
        setCookie("Consent granted", "Thank you for accepting cookies.", "true");
        doConsent();
    }

    // Run the consent code. We may be called either from grantConsent() or
    // from the main routine
    function doConsent() {
        //console.log("Consent was granted");
        // XXX edit your consent code here. As an example here's a function to
        // run Google Analytics
        //analytics();
    }

    // main routine
    //
    // First, check if cookie is present
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var c = cookies[i].trim();
        if (c.indexOf(consentString) == 0) {
            consentIsSet = c.substring(consentString.length, c.length);
        }
    }

    if (consentIsSet == "unknown") {
        $(cookieBanner).show();
        // Grant cookies by clicking a link
        //$("a:not(.noconsent)").click(grantConsent);
        //$(".denyConsent").click(denyConsent);
        // allow re-enabling cookies
        $(".allow-cookies").click(grantConsent);
    }
    else if (consentIsSet == "true") doConsent();
});